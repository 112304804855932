.login-register {

    .heading {
        margin: 0 0 30px;
        @include font-size(32px);
    }

    .control-label {
        width: 100%;
    }

    .help {
        font-size: 1rem;
        margin: 0 0 15px;
        line-height: 1.6;
    }

    .form-group:last-of-type {
        margin-bottom: 0;
    }

    .btn-primary, .btn-secondary {
        width: 100%;
    }

    .btn-primary, .btn-secondary {
        font-size: 1.25rem;
    }

    .forgotten-password {
        display: block;
        margin: 15px 0 0;
        text-align: center;
    }

    .btn-secondary {
        margin-bottom: 30px;
    }

    .col-divider {
        margin-bottom: 30px;
    }

    .nav-pills {
        margin-bottom: 15px;

        li {

            a {
                border: 1px solid #1a9fff;
            }

            &:not(.active) {
                a {
                    background-color: palette('iceWhite');
                    border: 1px solid #9fb4b6;

                    &:hover {
                        background-color: #eeeeee;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .help {
            font-size: 1.125rem;
            margin-bottom: 30px;
        }
        .btn-primary, .btn-secondary {
            font-size: 1.375rem;
        }
    }

    @include media-breakpoint-up(md) {
        .col-divider {
            border-right: 1px solid lighten(palette('outerSpace'), 70%);
            margin-bottom: 60px;
        }
        .help {
            font-size: 1.125rem;
        }
        .btn-primary, .btn-secondary {
            font-size: 1.5rem;
        }
        .heading {
            &--h1 {
                @include font-size(42px);
            }
            &--h2 {
                @include font-size(32px);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .help {
            font-size: 1.25rem;
        }
    }
    .radio-group-switch {
        width: 100%;
    }
}

.info-tooltip {
    position: relative;
    display: inline-block;
}


.info-tooltip .info-tooltip_text {
    visibility: hidden;
    width: 250px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0 !important;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: -360%;
    margin-left: -60px !important;

    opacity: 0;
    transition: opacity 0.3s;

    line-height:20px !important;
}

.info-tooltip .info-tooltip_text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.info-tooltip:hover .info-tooltip_text {
    visibility: visible;
    opacity: 1;
}

.gdprPopup{
    display:none;
    position:absolute;
    margin:-55px -80% 0 105%;
    z-index:1;
    background:white;

    @include media-breakpoint-down(sm) {
        margin:0;
    }

    .content{
        display:inline-block;

        width: 100%;
        height: 100%;
        background: #F4F7F9;
        border: 1px solid #0077CC;
        position: relative;
        padding: 20px;
        text-align: center;

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
        }

        &:after {
            top: 9px;
            border-color: transparent #F4F7F9 transparent transparent;
            border-width: 21px;
            left:-39px;

            @include media-breakpoint-down(sm) {
                top: calc(100% - 3px);
                border-color: #F4F7F9 transparent transparent transparent;
                border-width: 21px;
                left:calc(50% - 21px);
            }
        }

        &:before {
            top: 11px;
            border-color: transparent #0077CC transparent transparent;
            border-width: 19px;
            left: -38px;

            @include media-breakpoint-down(sm) {
                top:100%;
                border-color: #0077CC transparent transparent transparent;
                border-width: 19px;
                left:calc(50% - 19px);
            }
        }

    }
}
