.pac-container {
    padding: 15px;
}
.pac-icon {
    display: none;
}
.pac-item {
    &:first-child {
        border-top: none;
    }
    &:last-child {
        margin-bottom: 15px;
    }
}
