.nav-tabs {
  border-bottom: none;
  @include clearfix();

  > li > a { // BS BS
      margin-right: 0;
  }

  .nav-item {
    float: left;
    margin-bottom: 0;

    + .nav-item {
      margin-left: 0;
    }
  }

  .nav-link {
    background-color: palette('outerSpace');
    border: none;
    color: palette('pureWhite');
    display: block;
    line-height: 3.125rem;
    padding: 0 15px 0;
    transition: background-color .2s;

    &.disabled {
      &.active, &:active, &:hover, &:focus {
        color: $nav-disabled-link-color;
        background-color: transparent;
        border-color: transparent;
      }
    }

    &:hover, &:active, &:focus {
        background-color: lighten(palette('outerSpace'), 10%);
    }
  }

  .nav-link.active,
  .nav-item.open .nav-link,
  .nav-item.active .nav-link {
      background-color: palette('iceWhite');
      border: none;
      color: palette('outerSpace');

      &.active, &:active, &:hover, &:focus {
          color: palette('outerSpace');
          background-color: palette('iceWhite');
      }
  }
}


$nav-arrow-width: 1200px;
$nav-arrow-width-xs: 2000px;
$nav-arrow-width-sm: 1400px;
$nav-arrow-width-md: 600px;
$nav-arrow-width-lg: 600px;

.nav-steps {
    &--vertical {
        @include descending-z-index;
        border-top: 2px solid #e8e8e8;
        margin: -1px 0 50px -1px;
        .nav-step {
            margin-top: 0px;
            transition: all 0.2s ease;
            border-right: 2px solid #e8e8e8;
            border-left: 2px solid #e8e8e8;
            &.active {
                > a {
                    color: palette('outerSpace');
                    background-color: #f5f5f5;
                    &:hover {
                        background-color: #f5f5f5;
                        color: palette('outerSpace');
                    }
                }

                &:not(.nav-step--end, .nav-step--no-progress) {
                    >a {
                        border: none;
                    }
                }

                .nav-steps__arrow {
                    &:before {
                        border-top: 100px solid palette('lochmara');
                    }
                    &:after {
                        border-top: 97px solid #f5f5f5;
                    }
                }
                &.warning {
                    .nav-steps__arrow {
                        &:before {
                            border-top: 100px solid $brand-danger;
                        }
                    }
                }
                &.nav-step-fill-50,
                &.nav-step-fill-75 {
                    .nav-steps__arrow {
                        &:before {
                            border-top: 100px solid $brand-warning;
                        }
                    }
                }
                &.nav-step-fill-100 {
                    .nav-steps__arrow {
                        &:before {
                            border-top: 100px solid $brand-success;
                        }
                    }
                }
            }

            > a {
                position: relative;
                display: flex;
                padding: 34px 10px 12px 10px;
                color: #51696b;
                align-items: center;
                background-color: white;
                margin-right: 0;
                border: none;
                &:hover {
                    background-color: white;
                    color: #51696b;
                }
                &.text-center {
                    display: block;
                }
            }
            &:first-of-type {
                > a {
                    padding-top: 20px;
                }
            }
            &--end {
                .nav-steps__arrow {
                    display: none;
                }
                >a {
                    border-bottom: 3px solid #e8e8e8;
                    padding-bottom: 20px;
                }
                &.active {
                    >a {
                        border-color: palette('lochmara');
                    }
                    &.nav-step-fill-50,
                    &.nav-step-fill-75 {
                        >a {
                            border-color: $brand-warning;
                        }
                    }
                    &.nav-step-fill-100 {
                        >a {
                            border-color: $brand-success;
                        }
                    }
                }
            }
            &--group-start {
                position: relative;
                margin-top: 50px;
                border-top: 2px solid #e8e8e8;
                &:before {
                    content: '...';
                    position: absolute;
                    top: -52px;
                    text-align: center;
                    width: 100%;
                    font-size: 40px;
                    line-height: 32px;
                    text-align: center;
                    color: #e8e8e8;
                    height: 50px;
                }
            }
            &--no-progress {
                >a {
                    padding: 20px;
                    border-bottom: 3px solid #e8e8e8;
                    display: block;
                    text-align: center;
                }
                &.active {
                    >a {
                        border-bottom: 3px solid palette('lochmara');
                    }
                }
            }
        }
    }
    &__heading {
        flex: 1;
    }
    &__arrow {
        position: absolute;
        box-sizing: content-box;
        width: 50%;
        height: 0;
        padding-left: 50%;
        padding-top: 20px;
        overflow: hidden;
        background: transparent;
        left: 0;
        bottom: -20px;
        &:after, &:before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            margin-left: -#{$nav-arrow-width};
            margin-top: -100px;
            border-left: $nav-arrow-width solid transparent;
            border-right: $nav-arrow-width solid transparent;
            border-top: 100px solid #e8e8e8;
            color: black;
            z-index: 1;
            @include media-breakpoint-up(xs) {
                margin-left: -#{$nav-arrow-width-xs};
                border-left: $nav-arrow-width-xs solid transparent;
                border-right: $nav-arrow-width-xs solid transparent;
            }
            @include media-breakpoint-up(sm) {
                margin-left: -#{$nav-arrow-width-sm};
                border-left: $nav-arrow-width-sm solid transparent;
                border-right: $nav-arrow-width-sm solid transparent;
            }
            @include media-breakpoint-up(md) {
                margin-left: -#{$nav-arrow-width-md};
                border-left: $nav-arrow-width-md solid transparent;
                border-right: $nav-arrow-width-md solid transparent;
            }
            @include media-breakpoint-up(lg) {
                margin-left: -#{$nav-arrow-width-lg};
                border-left: $nav-arrow-width-lg solid transparent;
                border-right: $nav-arrow-width-lg solid transparent;
            }
        }
        &:after {
            border-top: 97px solid white;
            z-index: 2;
        }
    }
    + .nav-steps {
        margin-top: 20px;
    }
}

.nav-steps-wrapper {
    .panel {
        position: relative;
        margin-bottom: 21px;
        .nav-steps__arrow {
            bottom: -17px;
            &:after {
                border-top-color: #f5f5f5;
            }
        }
    }

    .panel-heading {
        position: relative;
    }
    .js-tabcollapse-panel-heading {
        display: flex;
        align-items: center;
    }
}

.nav-stacked-wrapper {
    .nav-stacked--vertical {
        margin-bottom: 50px;
    }
}
