.profile {

    &__heading {
        font-size: 1.25rem;
        font-weight: 400;
        margin: 0 0 20px 0;

        @include media-breakpoint-up(md) {
            display: flex;
            font-size: 2rem;
            margin: 0 20px 0 0;
        }
    }

    &__sub-heading {
        font-size: 1rem;
        margin: 0 0 20px 0;

        @include media-breakpoint-up(md) {
            font-size: 1.25rem;
            text-align: left;
            margin: 0 0 26px 0;
        }

        .profile-bar & {
            text-align: center;
        }

        .link {
            @include media-breakpoint-down(sm) {
                .profile-bar & {
                    color: palette('supernova');
                    font-size: .875rem;
                    padding: 0 10px;
                }
            }
        }
    }

    .info-section {

        .expander {
            border: 1px solid lighten(palette('outerSpace'), 50%);
            color: lighten(palette('outerSpace'), 15%);
            font-weight: 600;
            margin-bottom: 5px;
            padding: 10px;
            text-align: center;
            transition: all .2s;

            &:hover {
                border-color: palette('outerSpace');
                color: palette('outerSpace');
                cursor: pointer;
            }
        }

        .map {
            background-color: #f1eee5;
            height: 0;
            margin-bottom: 15px;
            opacity: 0;
            transition: all .2s;
            width: 100%;

            &.expanded {
                height: 245px;
                opacity: 1;
            }
        }

        .address {
            font-size: 1rem;
            text-align: center;
        }

        .address-line {
            &:after {
                content: ", ";
            }
            &:last-child:after {
                content: "";
            }
        }

        .contact-line {
            font-weight: 600;
            line-height: 2;

            .icon {
                font-size: 1.25rem;
                margin-top: 0;
            }
        }

        .heading {
            margin-bottom: 15px;
        }

        .sub-heading {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0;
        }

        .facilities-list {
            list-style: none;
            margin: 0;
            padding: 0;

            .facility {
                [class*="icon-"] {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .description {
        font-size: 1rem;
        line-height: 1.5;
        margin: 0;
    }

    .related-heading {
        margin-bottom: 0;
    }


    .categories {
        font-size: 1rem;
        line-height: 1;
        margin: 0 0 15px;

        [class*="icon-"]:before {
            margin-top: -.35em;
        }

        .category {
            &:after {
                content: ", ";
            }
            &:last-child:after {
                content: "";
            }
            .category-link {
                color: palette('outerSpace');
                opacity: .8;

                &:hover, &:active, &:focus {
                    opacity: 1;
                }
            }
        }
    }

    .tab-content {
        @include clearfix();
        background: palette('iceWhite');
        margin: 0 -15px;
        padding: 15px;
    }

    .tab-content-main {
        padding-bottom: 0px;
    }

    .tab-content-additional {
        padding-top: 0px;
    }

    @include media-breakpoint-up(sm) {
        .related-heading {
            margin-bottom: 30px;
        }

        .description {
            margin-bottom: 60px;
            margin-top: -.25em;
        }

        .tab-content {
            background: transparent;
            border: none;
            margin: 0;
            padding: 0;

            &:last-of-type {
                .info-section:last-of-type {
                    border: none;
                }
            }
        }

        .info-section {
            border-bottom: 1px solid darken(palette('iceWhite'), 10%);
            margin-bottom: 30px;
            padding-bottom: 30px;

            .address {
                text-align: left;
            }

            .address-line {
                display: block;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .description {
            font-size: 1.125rem;
            line-height: 1.75;
        }
    }
}

.profile-hero-dewis-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: white;
    padding: 6px;
    color: #C72033;
    font-weight: bold;
    font-size: 13px;

    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }

    a {
        color: #C72033;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    &__text {
        margin-left: 20px;
    }

    img {
        display: none;
        height: 32px;
        width: auto;

        @include media-breakpoint-up(sm) {
            display: inline-block;
        }
    }
}

.print-cover {
    display: none;
}

.terms-and-conditions-compact {
    display: none;
}
