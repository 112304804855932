.form-summary {
    &__row {
        display: flex;
    }

    dt {
        flex-basis: 40%;
        flex-shrink: 0;
        padding: 8px 0;

        &:after {
            content: ":";
        }
    }

    dd {
        padding: 8px 0 8px 12px;
        flex-basis: 60%;
    }
}
