.search-tools.flyout {

    .nav-tabs {
        border-top: 1px solid palette('outerSpace');
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        position: fixed;
        right: 0;
        transition: all .35s ease;
        z-index: 20;
    }

    .nav-item {
        border-right: 1px solid palette('outerSpace');

        &:last-of-type {
            border-right: none;
        }

        .nav-link {
            background-color: lighten(palette('outerSpace'), 10%);
            color: palette('pureWhite');
            font-size: 1rem;
        }

        &.active {
            .nav-link {
                background-color: lighten(palette('outerSpace'), 15%);
                &:active, &:hover, &:focus {
                    color: palette('pureWhite');
                    background-color: lighten(palette('outerSpace'), 15%);
                }
            }
        }
    }

    .tab-content {
        top: 100%;
        left: 0;
        right: 0;
        overflow: hidden;
        padding: 50px 0 0;
        position: fixed;
        transition: all .325s ease;
    }
    .tab-pane {
        height: 100vh;
        overflow-y: scroll;
        padding-bottom: 155px;
    }
    .search-pane {

    }
    .submit-group {
        bottom: -100px;
        left: 0;
        position: fixed;
        transition: all .35s ease;
        transition-delay: .2s;
        right: 0;

        .btn {
            font-size: 1.25rem;
        }
    }

    &.open {
        .nav-tabs {
            bottom: calc(100% - 50px);
        }

        .tab-content {
            bottom: 0;
            top: 0;
            z-index: 11;
        }

        .nav-link {
            padding-bottom: 0;
        }

        .submit-group {
            bottom: 0;
        }
    }
}
