.profile-photo {
    background-color: palette('pureWhite');
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    border: 1px solid lighten(palette('outerSpace'), 65%);
    height: 160px;
    width: 160px;
    margin-bottom: 8px;

    &.placeholder {
        background-color: #0077CC;
        background-size: 90%;
        background-image: url('/images/infoengine.png');

        &--dewis {
            background-image: url('/images/dewis-trans.png');
            background-size: 121px;
            background-color: #C72033;

            @include media-retina {
                background-image: url('/images/dewis-trans@x2.png');
            }
        }
    }
}
