.main-header {
    background-color: palette('lochmara');
    left: 0;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    z-index: 1000;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.3);

    + main {
        margin-top: 48px;
    }

    &__inner {
        background-color: palette('lochmara');
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;

        @include media-breakpoint-up(md) {
            padding: 30px 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 40px 0;
        }

        .stick & {
            padding: 10px;
        }

        // .shrink & {
        //     padding: 10px;
        // }
    }

    .home-icon-link {
        border: none;
    }

    .branding {
        display: inline-block;
        margin: 0 15px;
        min-height: 32px;
        padding-top: .275em;
        transition: all .15s;
        vertical-align: middle;
        width: 135px;

        img {
            display: block;
            height: 100%;
            width: 100%;
        }

    }

    // Small devices *only* - constant fixation!

    @include media-breakpoint-down(sm) {
        left: 0;
        position: fixed;
        right: 0;
        top: 0;

        .home-icon:before {
            margin-bottom: 2.5px;
        }

        .home-icon-link {
            float: right;
            padding: 0;
        }

        .branding {
            margin: 5px 15px;
            margin-left: 45px;
        }
    }

    @include media-breakpoint-up(sm) {
        .home-icon:before {
            margin-top: 5px;
            margin-bottom: 0;
        }

        .branding {
            margin: 10px 15px;
            margin-left: 45px;
        }
    }

    @include media-breakpoint-up(md) {
        position: absolute;
        right: 0;
        text-align: left;
        top: 0;

        .home-icon-link {
            display: none;
        }

        .branding {
            display: inline-block;
            margin: 0;
            vertical-align: middle;
            width: 160px;

            img {
                display: block;
                height: 100%;
                width: 100%;
            }

            .shrink &, .stick & {
                width: 125px;
            }

        }

        + main {
            margin-top: 106px;
        }
    }

    @include media-breakpoint-up(lg) {
        .branding {
            width: 200px;
        }

        &.stick, &.shrink {
            .branding {
                width: 180px;
            }
        }

        + main {
            margin-top: 130px;
        }
    }

    &.expand {
        animation-duration: .15s;
        animation-fill-mode: forwards;
        animation-name: expand;
        animation-timing-function: ease-out;
    }

    &.shrink {
        animation-duration: .15s;
        animation-fill-mode: forwards;
        animation-name: shrink;
        animation-timing-function: ease-out;
    }

    &.stick {
        animation-duration: .15s;
        animation-fill-mode: forwards;
        animation-name: stick;
        animation-timing-function: ease-out;
    }

}

@keyframes expand {
    0% {
        max-height: 60px;
        padding: 0;
        position: fixed;
    }
    50% {
        padding: 0;
        position: fixed;
    }
    100% {
        max-height: 140px;
        position: fixed;
    }
}

@keyframes shrink {
    0% {
        max-height: 140px;
        padding: 0;
        position: fixed;
    }
    50% {
        padding: 0;
        position: fixed;
    }
    100% {
        max-height: 60px;
        padding: 0;
        position: fixed;
    }
}

@keyframes stick {
    0% {
        max-height: 60px;
        opacity: 0;
        padding: 0;
        transform: translateY(-60px);
    }
    95% {
        opacity: 1;
    }
    100% {
        max-height: 60px;
        padding: 0;
        position: fixed;
        transform: translateY(0);
    }
}
