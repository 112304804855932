.navbar-toggler {
    background: none;
    color: white;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    &:hover, &:focus, &:active, &.active {
        border: none;
        outline: none;
    }

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;

        .main-header.stick &,
        .main-header.shrink & {
            font-size: 1rem;
            line-height: 1;
        }
    }

    .nav-icon, &.collapsed .nav-icon {
        width: 20px;
        height: 15px;
        position: relative;
        margin: 0;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            width: 30px;
            height: 20px;

            .main-header.stick &,
            .main-header.shrink & {
                width: 20px;
                height: 15px;
            }
        }

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: white;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2) {
                top: 7px;

                @include media-breakpoint-up(md) {
                    top: 10px;

                    .main-header.stick &,
                    .main-header.shrink & {
                        top: 7px;
                    }
                }
            }

            &:nth-child(3) {
                top: 14px;

                @include media-breakpoint-up(md) {
                    top: 20px;

                    .main-header.stick &,
                    .main-header.shrink & {
                        top: 14px;
                    }
                }
            }
        }
    }

    &:not(.collapsed) {
        .nav-icon {
            span {
                &:nth-child(1) {
                    top: 7px;
                    transform: rotate(135deg);

                    @include media-breakpoint-up(md) {
                        top: 10px;

                        .main-header.stick &,
                        .main-header.shrink & {
                            top: 7px;
                        }
                    }
                }

                &:nth-child(2) {
                    opacity: 0;
                    left: -30px;
                }

                &:nth-child(3) {
                    top: 7px;
                    transform: rotate(-135deg);

                    @include media-breakpoint-up(md) {
                        top: 10px;

                        .main-header.stick &,
                        .main-header.shrink & {
                            top: 7px;
                        }
                    }
                }
            }
        }
    }
}

.navbar-collapse {
    background-color: $state-info-bg;
    padding: 0;
    z-index: 1;
    ul {
        padding: 0;
        margin: 0;
        float: none;
        display: flex;
        flex-direction: column;
        overflow-y: visible;
        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
            a {
                display: block;
                padding: 15px;
                border-bottom: 1px solid rgba(255,255,255,0.3);
                text-align: left;
                color: #253031;
                background-color: $state-info-bg;
            }

            &:nth-child(-n+4) {
                a {
                    @include media-breakpoint-down(md) {
                        background-color: $brand-secondary;
                        color: white;
                    }
                }
            }
        }
    }

    &.in {
        height: 100vh;
        width: 100%;
        overflow-y: auto;
    }


    &.always {
        @include media-breakpoint-down(sm) {
            height: calc( 100vh - 50px) !important;
        }
        @include media-breakpoint-up(sm) {
            display: block !important;
            height: 100vh !important;
            max-width: 350px;
            overflow-y: auto !important;
            padding-bottom: 0;
            position: fixed;
            right: 0;
            top: 0;
            padding-top: 50px;
            transition: all 0.1s ease-in-out !important;
            right: -100%;

            .main-header.stick &, .main-header.shrink & {
                padding-top: 60px;
            }

            ul {
                height: 100%;
            }
        }

        @include media-breakpoint-up(md) {
            padding-top: 110px;

            .main-header.stick &, .main-header.shrink & {
                padding-top: 60px;
            }
        }

        @include media-breakpoint-up(lg) {
            padding-top: 130px;

            .main-header.stick &, .main-header.shrink & {
                padding-top: 60px;
            }
        }

        &.in {
            @include media-breakpoint-up(sm) {
                right: 0;
            }
        }
    }
}