@include media-breakpoint-down(sm) {
    .nav-container {
        background: darken(palette('lochmara'), 5%);
        margin-left:  ($grid-gutter-width / -2);
        margin-right: ($grid-gutter-width / -2);
        @include clearfix();
        box-shadow: inset 0 3px 5px rgba(0,0,0,.2);
    }
    .mobile-nav {
        float: left;
        width: 50%;

        @include clearfix();

        .list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .list-item-home {
            display: none;
        }

        .list-item {
            border-right: 1px solid darken(palette('lochmara'), 15%);
            float: left;
            font-size: 14px;
            width: 50%;

            [class^="icon"]:before,
            [class*=" icon-"]:before {
                display: block;
                font-size: 20px;
                margin: 5px 0;
            }

            .btn-primary, .btn-lg, .link {
                border: none;
                color: palette('pureWhite');
                display: block;
                font-size: 13px;
                height: 100%;
                line-height: 1.3;
                padding: 5px;
                white-space: normal;
                word-wrap: normal;
                width: 100%;

                &:hover, &:active, &:focus {
                    background: darken(palette('lochmara'), 15%);
                    text-decoration: none;
                }

            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .nav-container {
        margin-left:  0;
        margin-right: 0;
    }
}


@include media-breakpoint-down(xs) {
    .mobile-nav {
        .list-item {
            height: 72px;
        }
    }
}

@include media-breakpoint-up(md) {
    .nav-container {
        display: inline;
        flex: 1 1 auto;
    }
}
