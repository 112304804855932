.home-container {
    @include media-breakpoint-up(md) {
        > .row {
            display: flex;
            flex-flow: row;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            > [class*="col-"] {
                display: flex;
                flex-flow: column;
            }
        }
    }
}
