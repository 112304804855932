.meta-nav {
    font-size: .875rem;

    @include media-breakpoint-up(md) {
        float: right;
        text-align: right;

        .list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .list-item {
            display: inline-block;
            margin-right: 15px;
        }

        .link {
            font-size: 1rem;
            transition: all .2s;
            color: palette('pureWhite');

            &:hover, &:active, &:focus {
                color: palette('pureWhite');
                text-decoration: none;
            }

            [class^="icon"]:before,
            [class*=" icon-"]:before {
                font-size: 1.5rem;
                width: 1.5rem;
            }

        }

        .main-header.shrink &, .main-header.stick & {
            .list {
                padding-left: 15px;
            }

            .link {
                font-size: 1.125rem;
                line-height: 2rem;

                [class^="icon"]:before,
                [class*=" icon-"]:before {
                    font-size: 1.25rem;
                    width: 1.25rem;
                }

            }
        }

        @include media-breakpoint-up(lg) {
            .list {
                padding-left: 20px;
            }
            .link {
                font-size: 1.25rem;
            }
        }
    }
}
