.main-nav {
    font-size: .875rem;

    @include media-breakpoint-up(md) {
        display: block;
        width: 100%;

        .list {
            list-style: none;
            margin: 0;
            padding-left: 20px;
            transition: all .2s;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .list-item {
            display: inline-block;
            margin-right: 15px;

            &:last-child {
                margin-left: auto;
            }
        }

        .link {
            font-size: 1rem;
            transition: all .2s;
            color: white;

            [class^="icon"]:before,
            [class*=" icon-"]:before {
                font-size: 1.5rem;
                width: 1.5rem;
            }

        }

        .main-header.shrink &, .main-header.stick & {
            .list {
                padding-left: 15px;
            }

            .link {
                font-size: 1.125rem;
                line-height: 2.5rem;

                [class^="icon"]:before,
                [class*=" icon-"]:before {
                    font-size: 1.25rem;
                    width: 1.25rem;
                }

            }
        }

        @include media-breakpoint-up(lg) {
            .list {
                padding-left: 20px;
            }
            .link {
                font-size: 1.25rem;
            }
        }
    }
}