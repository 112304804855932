.tick-list {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
        @include font-size(16px);
        font-weight: normal;
        padding: 7px 0 7px 34px;
        position: relative;
        @include media-breakpoint-up(xs) {
            @include font-size(18px);
        }
        .icon-tick {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 6px;
            left: 0;
            background: #41a542;
            border-radius: 50%;
            color: white;
            width: 24px;
            height: 24px;
            @include media-breakpoint-up(xs) {
                top: 8px;
            }
            &:before {
                margin: 0;
            }
        }
    }
}
