.table-btn {
    margin: 4px 0;
}

.orderable_content {
    display: inline-flex;
    align-items: flex-end;

    a{
        text-decoration: none;
        color: #253031;
    }
}

.current_order {
    line-height: 1;
    margin-bottom: 2px;
    font-size: 15px;
}

.icon-sort{
    color: #9d9d9d;

    &:before{
        margin-right: 0 !important;
    }

    &.active {
        color: black;
    }
}