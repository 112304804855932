.privacy-notice {
    .divider {
        &_lg{
            padding-bottom: 40px;
        }

        &_md{
            padding-bottom: 20px;
        }

        &_sml{
            padding-bottom: 10px;
        }
    }

    &_header{

    }

    &_introduction{

    }

    &_who-are-we{

    }

    &_information-handling{

    }

    &_information-handling{

    }

    &_share-handling{

    }

    &_your-rights{

    }

    &_contact-us{
        ul{
            list-style: none;
        }
    }
}