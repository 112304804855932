.hero {
    background-color: palette('outerSpace');
    background-position: 50% -80px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: -30px 0 0;
    padding-top: 25%;
    position: relative;

    .overlay {
        background-image: linear-gradient(transparent 0%, transparent 10%, black 50%);
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .heading,
    .sub-heading {
        color: palette('pureWhite');
        font-size: 2rem;
        font-weight: 400;
        text-align: center;
        margin: 0 0 15px;
        position: relative;
    }

    @include media-breakpoint-up(sm) {
        background-position: 50% -80px;
        padding-top: 15%;

        .overlay {
            background-image: linear-gradient(transparent 0%, transparent 20%, black 60%),
        }
    }

    @include media-breakpoint-up(md) {
        background-position: 50%;
        margin: -30px 0 30px;

        .overlay {
            background-image: linear-gradient(transparent 45%, black 95%);
            background-position: bottom left;
        }

        .heading {
            font-size: 2.625rem;
        }
    }
}
