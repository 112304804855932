.browse-tools {
    margin: ($grid-gutter-width / -2);
    padding: 0 ($grid-gutter-width / 2);

    .category-heading, .sub-category-heading {
        margin: 0 ($grid-gutter-width / -2);
        padding: ($grid-gutter-width / 2);
        position: relative;
    }

    > .category-heading, .category-sub-heading {
        border-bottom: 1px solid darken(palette('iceWhite'), 6%);
        font-size: 1.125rem;
        padding: $grid-gutter-width ($grid-gutter-width / 2);
    }

    .expander {
        background: transparent;
        border: none;
        padding: $grid-gutter-width ($grid-gutter-width / 2);
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
        width: 100%;

        &:focus, &:active {
            box-shadow: none;
            outline: none;
        }

        &:focus {
            border-color: palette('supernova');
        }

        &:disabled [class*="icon-"] {
            opacity: .3;
        }

        [class*="icon-"]:before {
            margin-right: 0;
            transition: transform .2s;;
        }

        &.collapsed [class*="icon-"]:before {
            transform: rotate(180deg);
        }

    }

    > .browse-list {
        background: darken(palette('iceWhite'), 6%);
        list-style: none;
        margin: 0 ($grid-gutter-width / -2);
        padding: 0 ($grid-gutter-width / 2);

        > .item, .category-heading {
            padding: 5px 0;
        }

        > .sub-category-heading {
            background: darken(palette('iceWhite'), 2%);
            border-top: 1px solid darken(palette('iceWhite'), 6%);
            margin: 0 -15px;
            padding: 15px;

            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: 1px solid darken(palette('iceWhite'), 6%);
            }

            > .browse-list {
                background: darken(palette('iceWhite'), 6%);
                list-style: none;
                margin: 15px -15px -15px;
                padding-left: ($grid-gutter-width);

                > .item {
                    padding: 5px 0;
                }
            }
        }

        .expander {
            border-left: 1px solid darken(palette('iceWhite'), 6%);
            padding: 15px;
            text-align: center;
            width: auto;
        }
    }

    > .current-list {
        padding: 30px;
        margin: 0 -15px;
        border-bottom: 1px solid darken(palette('iceWhite'), 6%);

        > .item {
            padding-top: 10px
        }

        > .nested-item {
            list-style: none;
            padding-left: 15px;
        }
    }
}
