.spotlight {
    background: lighten(palette('tropicalBlue'), 10%);

    .heading {
        width: 100%;
        margin: ($grid-gutter-width / 2) 0;
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }

    .image {
        overflow: hidden;

        @include media-breakpoint-up(xs) {
            height:125px;
        }

        @include media-breakpoint-up(sm) {
            height:145px;
        }

        @include media-breakpoint-up(lg) {
            height:200px;
        }
    }

    .img-responsive {
        width:100%;
        object-fit: cover;
        object-position: center;

        @include media-breakpoint-up(xs) {
            height:125px;
        }

        @include media-breakpoint-up(sm) {
            height:145px;
        }

        @include media-breakpoint-up(lg) {
            height:200px;
        }
    }

    .link-wrap {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-up(sm) {
            flex-direction: column;
        }

        .body {
            max-height: 150px;
        }

        .description {
            flex: 1 auto;
        }
    }

    @include media-breakpoint-up(sm) {

        background: transparent;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;

        [class*="col-"] {
            display: flex;
            flex: 1 auto;
            flex-flow: column;
        }

        .heading {
            margin: 0 0 ($grid-gutter-width / 2);
        }

    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0;

        .heading {
            margin: ($grid-gutter-width / 2) 0;
        }
    }
}
