.home-search {

    .row {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .field-container {
            display:flex;
            flex-direction: row;
            @include media-breakpoint-up(md) {
                flex-direction: column;
            }

            .select-container {
                width:65%;

                @include media-breakpoint-up(sm) {
                    width:80%;
                }

                @include media-breakpoint-up(md) {
                    width:100%;
                }
            }
        }
    }

    .col-submit {
        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    .control-label {
        background: lighten(palette('outerSpace'), 10%);
        color: palette('supernova');
        display: block;
        font-size: 1rem;
        margin-bottom: 5px;
        float: left;
        width: 35%;
        padding: 0 15px 0;
        line-height: 2.75rem;
    }

    .control {
        border: none;
        border-radius: 0;
        font-size: 1rem;
        line-height: 2.75rem;
        padding: 0 15px;
        width: 65%;
        height: 2.75rem;
        float: left;

        @include media-breakpoint-up(md) {
            margin-bottom: 15px;
        }

        &:focus {
            outline: 4px solid palette('supernova');
        }
    }

    .btn-submit {
        align-self: flex-end;
        background-color: palette('supernova');
        border: none;
        border-radius: 0;
        flex: 1 auto;
        font-weight: 600;
        transition: background-color .2s;
        width: 100%;

        &:hover, &:focus {
            background-color: lighten(palette('supernova'), 10%);
        }

        &:focus {
            outline: none;
        }
    }

    #home-search-category {
        & ~ .select2-container {
            .select2-selection {
                background-color: palette('pureWhite');
                min-height: 44px;
                height: 44px;

                &__rendered,
                &__placeholder {
                    font-size: 1.25rem;
                    line-height: 2.75rem;
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .control-label {
            width: 20%;
        }
        .control {
            width: 80%;
        }
        .btn-submit {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 30px;
        .control-label {
            background: transparent;
            float: none;
            line-height: 1;
            padding: 0;
            width: 100%;
        }
        .control  {
            float: none;
            margin-bottom: 0;
            width: 100%;
        }
    }
    @include media-breakpoint-up(md) {

    }
    @include media-breakpoint-up(lg) {
        .control-label {
            font-size: 1.25rem;
        }
        .control  {
            font-size: 1.25rem;
        }
    }
}
