$rangeslider:               ".rangeslider";
$rangeslider--horizontal:   ".rangeslider--horizontal";
$rangeslider--vertical:     ".rangeslider--vertical";
$rangeslider--disabled:     ".rangeslider--disabled";
$rangeslider__fill:         ".rangeslider__fill";
$rangeslider__handle:       ".rangeslider__handle";
$rangeslider__output:       ".rangeslider__output";

#{$rangeslider},
#{$rangeslider__fill} {
    display: block;
    box-shadow: inset 0px 1px 3px rgba(0,0,0,0.3);
}

#{$rangeslider} {
    background: darken(palette('tropicalBlue'), 10%);
    margin-top: 10px;
    position: relative;
}

#{$rangeslider--horizontal} {
    height: 20px;
    width: 100%;
}

#{$rangeslider--vertical} {
    width: 20px;
    min-height: 150px;
    max-height: 100%;
}

#{$rangeslider--disabled} {
    opacity: .4;
}

#{$rangeslider__fill} {
    background: palette('lochmara');
    position: absolute;

    #{$rangeslider--horizontal} & {
        top: 0;
        height: 100%;
    }

    #{$rangeslider--vertical} & {
        bottom: 0;
        width: 100%;
    }
}

#{$rangeslider__handle} {
    background: palette('pureWhite');
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 40px;
    position: absolute;
    background-image: linear-gradient(rgba(white, 0), rgba(black, .10));
    box-shadow: 0 0 2px rgba(black, .3);

    &:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(rgba(black, .13), rgba(white, 0));
        border-radius: 50%;
    }

    &:active {
        background-image: linear-gradient(rgba(black, .10), rgba(black, .12));
    }

    #{$rangeslider--horizontal} & {
        top: -10px;
        touch-action: pan-y;
        -ms-touch-action: pan-y;
    }

    #{$rangeslider--vertical} & {
        left: -10px;
        touch-action: pan-x;
        -ms-touch-action: pan-x;
    }
}

#{$rangeslider__output} {
    color: lighten(palette('outerSpace'), 20%);
    display: inline;
    font-size: 1.5rem;
}

input[type="range"]:focus + #{$rangeslider} #{$rangeslider__handle} {
    outline: 4px solid palette('supernova');
}
