.register-cta {
    padding: $grid-gutter-width / 2;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: palette('iceWhite');
    align-items: center;
    @include media-breakpoint-down(sm) {
        margin: ($grid-gutter-width / 2) 0;
    }

    &__section {
        flex-basis: 100%;
        @include media-breakpoint-down(sm) {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
        @include media-breakpoint-up(sm) {
            flex-basis: 50%;
            &:nth-of-type(odd) {
                padding-right: $grid-gutter-width;
                border-right: 1px solid rgba(0, 0, 0, 0.06);
            }
            &:nth-of-type(even) {
                padding-left: $grid-gutter-width;
            }
        }

    }

    &__heading {
        @include font-size(36px);
        margin: 0 0 20px 0;
        @include media-breakpoint-up(xs) {
            @include font-size(48px);
        }
    }

    &__subheading {
        @include font-size(16px);
        @include media-breakpoint-up(xs) {
            @include font-size(18px);
        }
    }

    .btn-cta {
        @include font-size(20px);
        display: flex;
        width: 100%;
        margin-top: 30px;
        max-width: 264px;
        align-items: center;
        flex: 1 auto;
        justify-content: center;
    }

    @include media-breakpoint-up(xs) {
        padding: $grid-gutter-width;
    }
}
