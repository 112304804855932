body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    &.noscroll {
        left: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
    }
}

main {
    flex: 1 auto;
    padding-top: 30px;
}

@include media-breakpoint-up(md) {
    main {
        padding: 30px 0;
    }
}

@include media-breakpoint-up(lg) {
    .hero-mode {
        margin-bottom: -30px;
    }
}

.main-inner-flush {
    margin-top: -30px;
}

.page-content {
    p {
        @include font-size (18px, 1.75);
    }
}

.w-100 {
    width: 100%;
}

.d-none {
    display: none !important;;
}

@each $breakpoint, $value in $grid-breakpoints {
     @include media-breakpoint-up($breakpoint) {
        .d-#{$breakpoint}-block {
            display: block !important;
        }

        .d-#{$breakpoint}-inline-block {
            display: inline-block !important;
        }

        .d-#{$breakpoint}-flex {
            display: flex !important;
        }

        .d-#{$breakpoint}-inline-flex {
            display: inline-flex !important;
        }

        .d-#{$breakpoint}-none {
            display: none !important;
        }
    }
}
