.admin-tabbed-form {
    .nav-tabs {
        background: palette('iceWhite');
        flex: 1 auto;

        > li a {
            color: lighten(palette('outerSpace'), 35%);
            border: none;
            text-align: center;
            padding: 15px;

            &:hover, &:active, &:focus {
                background: transparent;
                color: palette('outerSpace');
            }

            &.has-error {
                color: $brand-danger;
            }
        }

        > li.active a {
            background: #fff;
            border: none;
            border-left: 4px solid palette('lochmara');
            color: palette('outerSpace');

            &.has-error {
                color: $brand-danger;
            }
        }
    }

    .tab-content {
        > fieldset {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .control-label, .form-control {
            font-size: 1rem;
            line-height: 1.5;
            padding: 10px 15px;
        }

        .control-label {
            padding: 0;
        }
    }
    &__sidebar {
        display: flex;
        flex-direction: column;
        height: 100%;

        .btn-trash {
            display: flex;
            border-width: 2px;
        }
    }
}

.convert-account-area {
    display: none;
}

.floating-tandc {
    padding: 15px 30px 0 15px;

    .alert{
        display: none;
    }
}

.admin-tabbed-form-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
        justify-content: flex-end;
    }
    .btn {
        @include media-breakpoint-up(sm) {
            margin-bottom: 15px;
        }
        &:not(:last-child) {
            margin-right: 15px;

        }
    }
}
