.admin-content {
    position: relative;
    > h1 {
        margin: 5px 0 30px;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        flex-wrap: wrap;
    }
    &__actions {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
    &__action {
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
    .table {
        td {
            vertical-align: middle;
        }
    }
}
