.profile-nav-tabs {
    margin: 0;
    text-align: center;

    .mobile-nav {
        background: palette('outerSpace');
        width: 100%;

        .list-item {
            border-right: 1px solid darken(palette('outerSpace'), 25%);
            width: 33.3333%;

            &:last-child {
                border-right: none;
            }
        }

        .link {
            padding: 5px 5px 10px;
            transition: background .2s;

            &.active, &:hover, &:active, &:focus {
                background: lighten(palette('outerSpace'), 15%);
            }
        }
    }
}
