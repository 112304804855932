.search-results {
    .heading {
        font-weight: 400;
        font-size: 1.5rem;
        margin: 0 0 30px;
    }

    .pagination {
        font-size: 1.5rem;

        > .active > span {
            font-weight: 600;
        }
    }

    .sort-by {
        margin-bottom: $grid-gutter-width;

        .btn {
            background: palette('outerSpace');
            border: 1px solid lighten(palette('outerSpace'), 15%);
            color: lighten(palette('outerSpace'), 50%);
            line-height: 1.875rem;
            margin: 0 -4px 0 0;
            padding: 0 5px;

            &:hover {
                background-color: palette('iceWhite');
            }

            &:active {
                color: palette('outerSpace');
            }

            &.active {
                background: palette('iceWhite');
                border-color: lighten(palette('outerSpace'), 45%);
                box-shadow: none;
                color: palette('outerSpace');
                padding: 2px 8px;

                &:hover, &:active {
                    color: palette('pureWhite');
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .heading {
            font-size: 1.75rem;
        }
    }

    @include media-breakpoint-up(md) {
        .heading {
            font-size: 1.875rem;
        }
    }

    .shortlistheading {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 20px;
      &_title {
        position: relative;
        width:460px;
        max-width:460px;
        display: inline-block;
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }

}
