.language-selection-modal {
    .modal-header {
        text-align: center;
    }
    .modal-title {
        font-size:1.5rem;
    }
    .modal-body {
        text-align: center;
        font-size: 1.25rem;
    }
    .modal-footer {
        font-size: .75rem;
        text-align: center;

        p:first-child {
            padding-bottom: 15px;
            position: relative;

             &:after {
                border-bottom: 1px solid darken(palette('iceWhite'), 15%);
                bottom: 0;
                content: " ";
                display: block;
                height: 1px;
                left: 50%;
                margin-left: -10%;
                position: absolute;
                width: 20%;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .modal-title {
            font-size: 1.75rem;
        }
        .modal-body {
            .btn {
                font-size: 1.5rem;
                line-height: 2;
            }
            padding: 60px 0;
        }
        .modal-footer {
            font-size: .8125rem;
        }
    }
}
