.home-about {
    background: palette('tropicalBlue');
    padding: $grid-gutter-width;
    margin-left: $grid-gutter-width / -2;
    margin-right: $grid-gutter-width / -2;

    a {
        color: #23527c;
    }

    .heading {
        margin: 0 0 15px;
    }

    .body {
        font-size: 1.125rem;

        p {
            margin: 0 0 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .img-responsive__container {
            .img-responsive {
                min-height: 215px;
                min-width: 250px;
            }

            display: flex;
            justify-content: center;
            padding-top: 20px;

            @include media-breakpoint-up(sm) {
                .img-responsive {
                    min-height: unset;
                    min-width: unset;
                }

                display: block;
                padding-top: 0;
                justify-content: unset;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        margin: ($grid-gutter-width / 2) 0;
    }

    @include media-breakpoint-up(md) {
        background: palette('iceWhite');
        flex: 1 auto;
        margin: 0;
        padding: $grid-gutter-width;
    }
}
