.org-entity {
    @include clearfix();
    margin: 0 ($grid-gutter-width / -2);
    padding: ($grid-gutter-width / 2);

    &:not(:last-child) {
        border-bottom: 1px solid darken(palette('iceWhite'), 10%);
    }

    &:nth-child(even) {
        background: palette('iceWhite');
    }

    .title {
        @include clearfix();
        line-height: 1.5;
        margin: 0 10px 10px 0;

        .link {
            display: block;
            float: left;
            margin-right: 5px;
        }
    }

    .status {
        color: palette('pureWhite');
        display: block;
        float: left;
        font-size: .825rem;
        font-weight: 600;
        margin: 3px 0;
        padding: 2px 5px;


        &.new {
            background: palette('mountainMeadow');
        }

        &.updated {
            color: palette('outerSpace');
            background: palette('supernova');
        }
    }

    .meta {
        @include clearfix();
        font-size: .825rem;
        margin-bottom: 15px;
    }

    .meta-item {
        display: block;
        float: left;
        margin-right: 10px;
        padding-right: 10px;
        word-break: keep-all;

        &:last-of-type {
            margin: 0;
            padding: 0;
        }
    }

    .categories {
        .category {
            &:after {
                content: ", ";
                display: inline-block;
                margin-left: -2px;
            }
            &:last-child:after {
                content: "";
            }
        }
    }

    .detail {
        @include clearfix();
      button {
          height: 50px;
      }
      .search-shortlist-list-wrapper {
        margin-top: 15px;
          @include media-breakpoint-up(lg) {
              width: 25% !important;
          }
      }
    }

    .hidden-contact {
        display:none;
    }

    .hidden-address{
        display:none;
    }

    .image {
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        border: 1px solid lighten(palette('outerSpace'), 65%);
        float: left;
        margin-right: 15px;
        margin-top: 5px;
        padding-top: 40%;
        width: 40%;
    }

    .placeholder {
        background-color: #0077CC;
        background-size: 90%;
        background-image: url('/images/infoengine.png');
    }

    .description {
        font-size: .9375rem;
        line-height: 1.5;
        margin: 0;
    }

    .btn-shortlist {
        margin: 15px 0;
        width: 100%;
    }

    @include media-breakpoint-up(xs) {
        .btn-shortlist {
            float: left;
            width: auto;
        }
        .status {
            float: right;
            margin: 0;
        }
        .image {
            padding-top: 25%;
            width: 25%;
        }
    }

    @include media-breakpoint-up(sm) {
        margin: 0 0 30px;

        .title {
            font-size: 1.25rem;
            margin: 0 0 10px;
        }
        .meta {
            font-size: 1rem;
        }
        .image {
            padding-top: 30%;
            width: 30%;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 0;

        &:nth-child(even) {
            background: transparent;
        }
        .description {
            font-size: 1rem;
        }
        .image {
            padding-top: 25%;
            width: 25%;
        }
    }
}
