.service-browser {
    margin-left:  ($grid-gutter-width / -2);
    margin-right: ($grid-gutter-width / -2);
    margin-bottom: 0;

    .nav-item {
        width: 50%;

        > .nav-link {
            margin-right: 0;
        }

        &.active {
            > .nav-link {
                background: lighten(palette('lochmara'), 50%);

                &:hover, &:active, &:focus {
                    background: lighten(palette('lochmara'), 50%);
                }
            }
        }
    }

    .nav-tabs {
        font-size: 1em;
        text-align: center;
    }

    .tab-pane {
        @include clearfix();
        padding: 15px;

        &.active {
            background: lighten(palette('lochmara'), 50%);
        }
    }

    .service-list {
        @include clearfix();
        font-size: .875rem;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .service-list-item {
        margin-bottom: 10px;
        width: 100%;

        &.county {
            float: left;
            font-size: 1rem;
            min-height: 125px;
            width: 50%;

            .heading {
                clear: both;
                display: block;
            }
        }

        &.town {
            font-size: .8125rem;
            list-style: none;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .browse-all {
        clear: both;
    }

    .more {
        background: palette('lochmara');
        border-top: none;
        color: palette('pureWhite');
        display: block;
        font-size: .875rem;
        margin: 0 -15px -15px;
        padding: 15px 0;
        text-align: center;
        transition: background .15s;

        &:hover, &:active, &:focus {
            background: darken(palette('lochmara'), 15%);
            text-decoration: none;
        }
    }

    @include media-breakpoint-up(sm) {
        margin: 0;

        .tab-pane {
            &.active {
                background: palette('iceWhite');
            }
        }

        .nav-item {
            &.active {
                .nav-link {
                    background: palette('iceWhite');

                    &:hover, &:active, &:focus {
                        background: palette('iceWhite');
                    }
                }
            }
        }

        .nav-tabs {
            font-size: 1.25rem;
        }

        .more {
            background: palette('iceWhite');
            border-top: 1px solid darken(palette('iceWhite'), 10%);
            color: palette('lochmara');

            &:hover, &:active, &:focus {
                background: palette('iceWhite');
                text-decoration: none;
            }
        }

        .service-list {
            &.categories {
                width: 100%;
            }
        }

        .service-list-item {
            margin-bottom: 10px;

            &.county {
                font-size: 1.125rem;
                width: 100%;
            }

            > .service-list {
                margin-left: 0;
                padding: 5px 0 0;
                width:100%;
            }
        }

        .service-list {
            font-size: 1rem;
            float: left;
            width: 33.3333%;
        }
    }

    @include media-breakpoint-up(md) {
        .nav-tabs {
            font-size: 1.5rem;
        }
    }

    .icon-chevron-up:before {
        font-size: 10px;
        line-height: 1px;
        transition: -webkit-transform .2s;
        transition: transform .2s;
    }

    .collapsed {
        .icon-chevron-up:before {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }

    .towns {
        width: 100%;
        padding-left: 15px;
    }

}
