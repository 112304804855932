.modal-header-logo {
    position: relative;
    display: inline-block;
    min-width: 70px;
    min-height: 70px;
    width: 70px;
    height: 70px;
    margin-right: 14px;
    @include media-breakpoint-up(sm) {
        min-width: 100px;
        min-height: 100px;
        width: 100px;
        height: 100px;
        margin-right: 20px;
    }
    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../images/infoengine-logo-square.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}
