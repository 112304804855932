.profile-bar {
    padding: 20px 0;
    position: relative;
    background: #424444;
    color: palette('iceWhite');

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            padding: 20px 0 5px 0;
        }
    }

    &__tools {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            width: 100%;
        }
    }

    .actions {
        @include media-breakpoint-down(xs) {
            margin-bottom: 20px;
        }
    }

    .action-list {
        @include clearfix();
        font-size: .875rem;
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .action-item {
        display: inline-block;

        .btn {
            @include button-variant(palette('pureWhite'), rgba(0,0,0,0), darken(palette('iceWhite'), 10%));
            font-size: .875rem;
        }
    }

    .select2 {
        display: inline-block;

        @include media-breakpoint-up(sm) {
            width: 250px !important;
            margin-left: 5px;
        }
    }

    @include media-breakpoint-up(sm) {
        margin-bottom: 70px;

        .categories {
            display: block;
            float: left;
            font-size: 1.125rem;
            line-height: 86px;
            visibility: visible;

            [class*="icon-"]:before {
                margin-top: -.05em;
            }

            .category {
                .category-link {
                    color: palette('iceWhite');
                }
            }
        }

        .action-item {
            .btn {
                font-size: 1.125rem;
            }
        }
    }
}
