.admin-nav {
    box-shadow: 0 1px 3px rgba(0,0,0,.15);
    margin-bottom: $grid-gutter-width;

    .list-item {
        margin: 0;

        &:last-child .nav-link {
            border-bottom: none;
        }
    }

    .nav-link {
        border-bottom: 2px solid palette('iceWhite');
        color: lighten(palette('outerSpace'), 5%);
        font-size: .875rem;
        margin: 0;
        padding: 15px;

        &.active, &:hover, &:focus, &:active {
            background: palette('iceWhite');
            color: palette('outerSpace');
        }

        [class*="icon-"] {
            text-align: center;

            &:before {
                font-size: 1.5rem;
                width: 15%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .nav-link {
            font-size: 1rem;
        }
    }
}

.admin-nav__mobile-header {
    display:block;
    border-bottom: 2px solid #F4F7F9;
    color: #303e40;
    font-size: .875rem;
    margin: 0;
    padding: 15px;
    cursor: pointer;

    div {
        width: 25px;
        height: 3px;
        background-color: black;
        margin: 4px 0;
    }
}

@include media-breakpoint-down(sm) {
    .admin-nav {
        .btn {
            width:100%;
        }

        .dropdown-menu {
            width:100%;
            z-index: 999;
        }
    }
}
