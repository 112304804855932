.shortlist-list {
    .well {
        margin-top: 10px;
    }

    #shortlist-delete {
        width: 100%;
    }
}

.search-shortlist-list-wrapper {
    width: 100%;
    display: inline-block;
    float: left;
    @include media-breakpoint-up(xs) {
        margin: 0 0 15px 5px;
        width: 175px;
    }

    @include media-breakpoint-up(sm) {
        width: 280px;
    }
}
.no-arrow {
    span[role="presentation"] {
        display: none;
    }
}

.search-shortlist-list-wrapper + button {
    padding: 0 11px !important;
    .btn-shortlist-text {
      display: none;
    }
    .icon-tick {
      margin-left: 6px;
    }
    .icon-star {
      margin-left: 6px;
    }
}

.new-shortlist-input {
    width: 100%;
    margin-top: 15px;
    display: inline-block;

    @include media-breakpoint-up(sm) {
        margin-top: 5px;
    }

    &-button {
        margin-top: 15px;
        width: 100%;
        .btn {
            width: 100%;
        }

        @include media-breakpoint-up(sm) {
            width: auto;
            margin-top: 5px;
        }
    }
}

#shortlist-send-form {
    .btn-primary {
        width: 100%;
    }
}

.all-to-short {
    position: relative;
    left: 0;
    //display: block;
    @include media-breakpoint-up(sm) {
        left: 2%;
    }

    @include media-breakpoint-up(md) {
        display: contents;
    }

    @include media-breakpoint-up(lg) {
    //left: 20%;
    //display: contents;
    }

    span[role="presentation"] {
        display: none;
    }

    button {
        height: 50px;
        width: 100%;
        margin-top: 15px;
    }

    @include media-breakpoint-up(xs) {
        padding-right: 0;
        button {
            width: 54px;
            margin-top: 0;
        }
    }
}
