.main-footer {
    background: palette('outerSpace');
    color: palette('pureWhite');
    padding: 30px 0;

    .heading {
        font-size: 1.125rem;
    }

    .link-list {
        list-style: none;
        margin: 0;
        padding: 0;

        .link {
            color: palette('pureWhite');
            font-weight: 200;
            font-size: .8125rem;
            &:hover, &:active, &:focus {
                color: palette('pureWhite');
            }

        }

    }

    .social {
        margin-bottom: 30px;
        .link {
            font-size: 1rem;
        }

        [class*="icon-"]:before {
            font-size: 2.5rem;
            margin: -2px 0 0;
        }
    }

    .attributions {
        border-top: 1px solid lighten(palette('outerSpace'), 20%);
        margin-bottom: 30px;
        margin-top: 30px;
        padding-top: 30px;
    }

    .copyright {
        .symbol {
            font-size: 1.25rem;
        }
    }

    .plug {
        text-align: left;

        .link {
            color: palette('pureWhite');
        }
    }

    @include media-breakpoint-up(sm) {
        .plug,
        .main-footer-menu {
            text-align: right;
        }
    }
}
