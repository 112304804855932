.help-block_container {
    .help-block_img-container {
        position: relative;
        overflow: hidden;
        height: 300px;

        img {
            position: absolute;
            width: 100%;
        }
    }
}
