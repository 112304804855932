.search-tools {
    box-shadow: 0 1px 3px transparentize(palette('outerSpace'), 0.8);
    width: 100%;

    .search-form {
        .btn {
            display: block;
            font-size: 1.5rem;
            float: left;
            width: 50%;
        }
        .form-control {
            background: palette('pureWhite');
            border: 2px solid darken(palette('iceWhite'), 10%);
        }
        .select2-selection--single {
            background-color: palette('pureWhite');
            border: 2px solid darken(palette('iceWhite'), 10%);
        }
    }

    .nav-item {
        background-color: palette('iceWhite');
        font-size: 1.375rem;
        text-align: center;
        width: 50%;

        &.active {
            .nav-link {
                background-color: palette('iceWhite');

                &:active, &hover, &:focus {
                    color: palette('outerSpace');
                    background-color: palette('iceWhite');
                }
            }
        }
    }

    .tab-pane {
        padding: 15px;

        &.active {
            background: palette('iceWhite');
        }
    }


    @include media-breakpoint-up(md) {
        .nav-item {
            font-size: 1.125rem;
        }
        .search-form {
            .control-label, .radio-control {
                font-size: 1.125rem;
            }
            .btn-submit {
                float: none;
                width: 100%;
            }
            .btn-cancel {
                display: none;
                visibility: hidden;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .nav-item {
            font-size: 1.25rem;
        }
        .search-form {
            .control-label, .radio-control {
                font-size: 1.25rem;
            }
        }
    }
}
