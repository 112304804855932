.create-form {
    position: absolute;
    top: 5px;
    right: 15px;

    .heading {
        float: left;
        margin-right: $grid-gutter-width / 2;
    }

    .select2-container {
        float: left;
        margin-right: 5px;
        padding-right: 50px;
        width: 250px !important;
    }

    .select2-selection__rendered {
        font-size: .85rem;
    }

    .btn-create, .btn-publish {
        float: left;
        position: relative;
        right: 0;
        top: 0;
    }
}
