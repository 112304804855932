/** ============================
 ** function circular-progress
 ** ============================ */
@function circular-progress($maxPercent, $percent, $color-bar, $color-back) {
    $increment: 360 / $maxPercent;
    $half: round($maxPercent / 2);
    $background-image: '';
    @if $percent < $half {
        $nextdeg: 90deg + ($increment * $percent);
        $background-image: 'linear-gradient(90deg, #{$color-back} 50%, transparent 50%, transparent),
        linear-gradient(#{$nextdeg}, #{$color-bar} 50%, #{$color-back} 50%, #{$color-back})';
    }
    @else {
        $nextdeg: -90deg + ($increment * ($percent - $half));
        $background-image: 'linear-gradient(#{$nextdeg}, #{$color-bar} 50%, transparent 50%, transparent),
        linear-gradient(270deg, #{$color-bar} 50%, #{$color-back} 50%, #{$color-back})';
    }
    @return unquote($background-image);
}


/** ============================
 ** Mixins Circular Progress
 ** ============================ */
@mixin circular-progress($percent-num, $color1: "#03A9F4") {
    background: circular-progress(100, $percent-num, $color1, #E0E0E0);
    color: $color1;
    border-radius: 50%;
    height: 30px;
    margin: 2em auto;
    position: relative;
    min-width: 30px;
    width: 30px;
    margin: 0 20px 0 10px;
    transition: all .3s linear;
    &::before {
        background-color: #FAFAFA;
        border-radius: 50%;
        color: rgba(black, .54);
        content: '';
        font-size: 72px;
        font-weight: bold;
        height: 100%;
        left: 0;
        line-height: 30px;
        position: absolute;
        text-align: center;
        top: 0;
        transform: scale(.87);
        width: 100%;
    }
}


.circular-progress-0 {
    @include circular-progress(0, $brand-secondary);
}
.circular-progress-25 {
    @include circular-progress(25, $brand-secondary);
}
.circular-progress-50 {
    @include circular-progress(50, $brand-warning);
}
.circular-progress-75 {
    @include circular-progress(75, $brand-warning);
}
.circular-progress-100 {
    @include circular-progress(100, $brand-success);
}
.circular-progress-tick {
    &:after {
        @include icon-font;
        color: inherit;
        content: $icon-tick;
        position: absolute;
        top: calc(50% - 11px);
        left: calc(50% - 10px);
        font-size: 22px;
        margin-top: 0;
    }
}
.circular-progress-alert {
    &:after {
        @include icon-font;
        color: $brand-danger;
        content: $icon-attention;
        position: absolute;
        top: calc(50% - 8px);
        left: calc(50% - 6px);
        font-size: 14px;
        margin-top: 0;
    }
}
