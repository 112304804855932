/*
 * Sortable lists.
 */




.panel-sortable {
    .panel-heading {
        cursor: pointer;
    }
}

.sortable-chosen {
    opacity: 0.5 !important;
}
