.service-card {
    @include clearfix();
    border-bottom: 1px solid darken(palette('iceWhite'), 10%);
    margin: 0 0 15px;
    padding-bottom: 15px;
    padding-top: 15px;

    &:last-child {
        border-bottom: none;
    }

    .image {
        float: left;
        width: calc(33% - 15px);
        margin: 0 15px 0 0;
    }

    .description {
        width: 63%;
        float: right;
        font-size: .875rem;
        margin-bottom: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        p {
            margin: -5px 0 0;
        }

        .heading {
            padding-left:0;
            margin-top:0;
        }
    }

    .link-wrap {
        color: palette('outerSpace');
        font-weight: 200;
        flex: 1 auto;

        &:hover {
            .service-card & {
                background: #fff;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        border-bottom: none;
        box-shadow: 0 2px 3px rgba(0,0,0,.15);
        display: flex;
        flex: 1 auto;
        flex-flow: column;
        margin-bottom: 0;
        min-height: 300px;
        padding-bottom: 0;
        padding-top: 0;

        .image {
            float: none;
            margin: 0;
            width: 100%;
        }

        .description {
            float: none;
            width: 100%;
            border-top: 1px solid darken(palette('iceWhite'), 5%);
            flex: 1 auto;
            padding: 15px;

            .heading {
                margin: 0 0 15px;
                padding: 0;
            }

            .body {
                margin: 0;
            }
        }

        .link-wrap {
            background: palette('iceWhite');
            color: palette('outerSpace');
            transition: all .2s;
            &:hover {
                background: lighten(palette('iceWhite'), 3%);
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .description {
            font-size: .9375rem;
            line-height: 1.75;
        }
    }
}
