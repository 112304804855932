.error-page {
    text-align: center;

    .heading {
        color: lighten(palette('outerSpace'), 25%);
        font-size: 2rem;
        margin: 60px;
    }

    .body {
        color: lighten(palette('outerSpace'), 25%);
        font-size: 1rem;
    }

    @include media-breakpoint-up(sm) {
        .heading {
            font-size: 2.5rem;
            margin: 60px;
        }

        .body {
            font-size: 1.5rem;
        }
    }

}
