/*
 * Additional Trix Editor styles.
 */



// Editor toolbar styling

trix-toolbar {
    .button_group {
        button.trix-toolbar-heading {
            font-size: 18px;
            vertical-align: bottom;
        }
    }

    // This is copied from the core CSS, and styles the link input field in the
    // toolbar to have correct styling when changed to a "text" input rather than
    // a "url" field

    .dialogs .dialog.link_dialog input[type=text] {
        float: left;
        width: calc(100% - 120px);
    }
}

// Editor content styling

trix-editor {
    padding: 8px;

    p, h1, h2, h3, blockquote, pre, ul, ol {
        border: 1px dashed #eeeeee;
        padding: 3px;
        margin-bottom: 3px;
        margin-top: 0;
    }

    blockquote {
        border-left: 5px solid #eeeeee;
        padding-left: 20px;
    },

    ul {
        list-style-position: inside;
    }
}
