.spotlight-slot-type-group {
    display: inline-block;
    width: 100%;
}

.chosen-box {
    height: 73px;
    margin-top: 10px;
    
    &-not-selected {
        border: 1px dashed #999;
        line-height: 73px;
        text-align: center;
        
        img {
            display: none;
        }
        
        .chosen-address {
            display: none;
        }
    }
    
    &-selected {
        border: 2px solid #999;
    }
    
    @include media-breakpoint-up(lg) {
        margin-top: 0px;
    }
}

.chosen-labels {
    display: inline-block;
    max-width: 180px;
    font-size: 0.95rem;
}

.chosen-title {
    padding: 0px 5px;
    font-weight: bold;
}

.chosen-address {
    padding: 0 5px;
}

.chosen-image {
    background-color: palette('iceWhite');
    float: left;
    height: 59px;
    width: 59px;
    margin-top: 5px;
    margin-left: 5px;
}

.specific-entity-label {
    text-transform: capitalize;
}

.panel-body {
    .form-control {
        font-size: 1.1rem;
    }
}