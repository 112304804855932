/*
 * Autocomplete dropdown.
 */



// Container

.autocomplete-suggestions {
    // Required stuff
    position: absolute;
    display: none;
    z-index: 9999;
    overflow: hidden;
    overflow-y: auto;
    outline: 2px solid palette('supernova');
    background-color: palette('pureWhite');
    padding: 5px;
}

// Item

.autocomplete-suggestion {
    @include clearfix();
    cursor: pointer;
    padding: 14px;
    border-bottom: 1px solid palette('iceWhite');
    background-color: palette('pureWhite');

    &:last-child {
        border-bottom-width: 0px;
    }

    &:hover {
        background-color: palette('iceWhite');
    }
}

// Item text

.autocomplete-text {
    display: table;
}

.autocomplete-suggestion-heading,
.autocomplete-suggestion-subheading{
    font-size: .975rem;
    margin: 0 0 2px;

    &:last-child {
        margin-bottom: 0;
    }
}

.autocomplete-suggestion-heading{
    font-weight: bold;
}

.autocomplete-suggestion--organisation {
    color: palette('zest');
}

.autocomplete-suggestion--service {
    color: palette('mountainMeadow');
}

// Item image

.autocomplete-suggestion-image {
    height: 35px;
    width: 35px;
    display: inline-block;
    float: left;
    margin-right: 13px;
    background-color: palette('iceWhite');
}

@include media-breakpoint-up(sm) {
    .autocomplete-suggestion-image {
        height: 60px;
        width: 60px;
    }
}
